import React, { useEffect } from 'react';
import { IoCall } from 'react-icons/io5';
import { IoIosArrowDropright } from 'react-icons/io';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import FQAStyle from "./FQA.module.scss";
import Rectangle62 from "../../../image/Rectangle 62.png";
import Rectanglethe1 from "../../../image/Rectangle the1.png";
import Rectangle104 from "../../../image/Rectangle 104.png";
import Rectangle65 from "../../../image/Rectangle 65.png";
import Rectangle117 from "../../../image/Rectangle 117.png";
import FAQForm from "../../../../src/image/TIWA COMMERCIAL FAQ AND FORM.pdf";
import FAQForm2 from "../../../../src/image/KAPITAL CITY FORM AND FAQ.pdf";
import FAQForm3 from "../../../../src/image/ARIZON BROCHURE-4.pdf";
import kapiHomeLand from "../../../image/kapiHomeLand.jpg";
import arizonHomeLand from "../../../image/arizonHomeLand.jpg";
import TBA from "../../../image/tiwamax.jpg";

function FQA(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className={FQAStyle.holdALl}>
      <div>
        <div
          className={FQAStyle.holdAllTeamText}
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div className={FQAStyle.holdAllTeamText1}>
            <h5>Frequently Asked Questions</h5>
            <p>Here are all FAQ for all estates and house</p>
          </div>
        </div>
        <div className={FQAStyle.holdFrenqently}>
          <div className={FQAStyle.holdFrenqently2}>
            <div
              className={FQAStyle.holdMiniBox}
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={FQAStyle.miniBoxText}>
                <div className={FQAStyle.miniBoxText2}>
                  <div>
                    <p className={FQAStyle.LekkiPride1Text}>
                      Tiwa Garden City Phase II
                    </p>
                    <p className={FQAStyle.LekkiPride1Text2}>
                      View FAQ <HiOutlineArrowNarrowRight />
                    </p>
                  </div>
                  <div>
                    <a href={FAQForm2} download>
                      <IoIosArrowDropright className={FQAStyle.holdSVG} />
                    </a>
                  </div>
                </div>
              </div>
              <div className={FQAStyle.miniBoxImage}>
                <img
                  src={TBA}
                  alt="Rectangle62"
                  className={FQAStyle.miniBoxImage1}
                />
              </div>
            </div>

            <div
              className={FQAStyle.holdMiniBox}
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={FQAStyle.miniBoxText}>
                <div className={FQAStyle.miniBoxText2}>
                  <div>
                    <p className={FQAStyle.LekkiPride1Text}>
                      Kapital City Estate
                    </p>
                    <p className={FQAStyle.LekkiPride1Text2}>
                      View FAQ <HiOutlineArrowNarrowRight />
                    </p>
                  </div>
                  <div>
                    <a href={FAQForm2} download>
                      <IoIosArrowDropright className={FQAStyle.holdSVG} />
                    </a>
                  </div>
                </div>
              </div>
              <div className={FQAStyle.miniBoxImage}>
                <img
                  src={kapiHomeLand}
                  alt="Rectangle62"
                  className={FQAStyle.miniBoxImage1}
                />
              </div>
            </div>

            <div
              className={FQAStyle.holdMiniBox}
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={FQAStyle.miniBoxText}>
                <div className={FQAStyle.miniBoxText2}>
                  <div>
                    <p className={FQAStyle.LekkiPride1Text}>Arizon Estate</p>
                    <p className={FQAStyle.LekkiPride1Text2}>
                      View FAQ <HiOutlineArrowNarrowRight />
                    </p>
                  </div>
                  <div>
                    <a href={FAQForm3} download>
                      <IoIosArrowDropright className={FQAStyle.holdSVG} />
                    </a>
                  </div>
                </div>
              </div>
              <div className={FQAStyle.miniBoxImage}>
                <img
                  src={arizonHomeLand}
                  alt="Rectangle62"
                  className={FQAStyle.miniBoxImage1}
                />
              </div>
            </div>

            <div
              className={FQAStyle.holdMiniBox}
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={FQAStyle.miniBoxText}>
                <div className={FQAStyle.miniBoxText2}>
                  <div>
                    <p className={FQAStyle.LekkiPride1Text}>
                      Tiwa Commercial Hub
                    </p>
                    <p className={FQAStyle.LekkiPride1Text2}>
                      View FAQ <HiOutlineArrowNarrowRight />
                    </p>
                  </div>
                  <div>
                    <a
                      href="https://drive.google.com/file/d/1K8mkn26FsM3Z0dNgeQfD9HZxtnZVoVMz/view?usp=share_link"
                      download
                    >
                      <IoIosArrowDropright className={FQAStyle.holdSVG} />
                    </a>
                  </div>
                </div>
              </div>
              <div className={FQAStyle.miniBoxImage}>
                <img
                  src={Rectangle62}
                  alt="Rectangle62"
                  className={FQAStyle.miniBoxImage1}
                />
              </div>
            </div>

            <div
              className={FQAStyle.holdMiniBox}
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={FQAStyle.miniBoxText}>
                <div className={FQAStyle.miniBoxText2}>
                  <div>
                    <p className={FQAStyle.LekkiPride1Text}>Tiwa Garden City</p>
                    <p className={FQAStyle.LekkiPride1Text2}>
                      View FAQ <HiOutlineArrowNarrowRight />
                    </p>
                  </div>
                  <div>
                    <a
                      href="https://drive.google.com/file/d/1KGBY31pG3-9-mPeaRk3TaUTxykcpfdfO/view?usp=share_link"
                      download
                    >
                      <IoIosArrowDropright className={FQAStyle.holdSVG} />
                    </a>
                  </div>
                </div>
              </div>
              <div className={FQAStyle.miniBoxImage}>
                <img
                  src={Rectangle104}
                  alt="Rectangle62"
                  className={FQAStyle.miniBoxImage1}
                />
              </div>
            </div>

            <div
              className={FQAStyle.holdMiniBox}
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={FQAStyle.miniBoxText}>
                <div className={FQAStyle.miniBoxText2}>
                  <div>
                    <p className={FQAStyle.LekkiPride1Text}>Lekki Pride II</p>
                    <p className={FQAStyle.LekkiPride1Text2}>
                      View FAQ <HiOutlineArrowNarrowRight />
                    </p>
                  </div>
                  <div>
                    <a href={FAQForm} download>
                      <IoIosArrowDropright className={FQAStyle.holdSVG} />
                    </a>
                  </div>
                </div>
              </div>
              <div className={FQAStyle.miniBoxImage}>
                <img
                  src={Rectanglethe1}
                  alt="Rectangle62"
                  className={FQAStyle.miniBoxImage1}
                />
              </div>
            </div>

            <div
              className={FQAStyle.holdMiniBox}
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={FQAStyle.miniBoxText}>
                <div className={FQAStyle.miniBoxText2}>
                  <div>
                    <p className={FQAStyle.LekkiPride1Text}>Zylus Court</p>
                    <p className={FQAStyle.LekkiPride1Text2}>
                      View FAQ <HiOutlineArrowNarrowRight />
                    </p>
                  </div>
                  <div>
                    <a href={FAQForm} download>
                      <IoIosArrowDropright className={FQAStyle.holdSVG} />
                    </a>
                  </div>
                </div>
              </div>
              <div className={FQAStyle.miniBoxImage}>
                <img
                  src={Rectangle117}
                  alt="Rectangle62"
                  className={FQAStyle.miniBoxImage1}
                />
              </div>
            </div>

            <div
              className={FQAStyle.holdMiniBox}
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={FQAStyle.miniBoxText}>
                <div className={FQAStyle.miniBoxText2}>
                  <div>
                    <p className={FQAStyle.LekkiPride1Text}>Western Hilltop</p>
                    <p className={FQAStyle.LekkiPride1Text2}>
                      View FAQ <HiOutlineArrowNarrowRight />
                    </p>
                  </div>
                  <div>
                    <a href={FAQForm} download>
                      <IoIosArrowDropright className={FQAStyle.holdSVG} />
                    </a>
                  </div>
                </div>
              </div>
              <div className={FQAStyle.miniBoxImage}>
                <img
                  src={Rectangle65}
                  alt="Rectangle62"
                  className={FQAStyle.miniBoxImage1}
                />
              </div>
            </div>

            <div
              className={FQAStyle.holdMiniBox}
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={FQAStyle.miniBoxText}>
                <div className={FQAStyle.miniBoxText2}>
                  <div>
                    <p className={FQAStyle.LekkiPride1Text}>Hilton City</p>
                    <p className={FQAStyle.LekkiPride1Text2}>
                      View FAQ <HiOutlineArrowNarrowRight />
                    </p>
                  </div>
                  <div>
                    <a href={FAQForm} download>
                      <IoIosArrowDropright className={FQAStyle.holdSVG} />
                    </a>
                  </div>
                </div>
              </div>
              <div className={FQAStyle.miniBoxImage}>
                <img
                  src={Rectanglethe1}
                  alt="Rectangle62"
                  className={FQAStyle.miniBoxImage1}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={FQAStyle.holdAnytime}
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div>
            <h1 className={FQAStyle.Reach}>You Can Reach Us Anytime</h1>
            <p className={FQAStyle.question}>
              Have a question or doubt about our property?
            </p>
          </div>
          <button className={FQAStyle.callUs}>
            <IoCall />
            Call us
          </button>
        </div>
      </div>
    </section>
  );
}

export default FQA;