import React, { useState } from "react";
import style from "./index.module.scss";
import TestimonialVideo from "../../DB/TestimonialVideo";

export default function Index() {
  const [typePro, setTypePro] = useState("land");

  const changeNow = (e) => {
    setTypePro(e);
  };
  return (
    <section>
      <div className={style.holdAll1}>
        <div className={style.heroImage}>
          <div>
            <h1>Testimonial</h1>
          </div>
        </div>

        <div className={style.holdButton}>
          <div className={style.holdButton2}>
            <button
              onClick={() => changeNow("land")}
              className={`${
                typePro === "land" ? style.buttonActive : style.buttonNotActive
              }`}
            >
              Land Testimonial
            </button>
            <button
              onClick={() => changeNow("house")}
              className={`${
                typePro === "house" ? style.buttonActive : style.buttonNotActive
              }`}
            >
              House Testimonial
            </button>
          </div>
        </div>

        <div className={style.listOfVideo}>
          <div>
            {TestimonialVideo.map((item, e) => {
              if (item.type === typePro) {
                return (
                  <iframe
                    key={e}
                    className="w-full h-full md:h-[350px]"
                    src={item.iframe}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe>
                );
              }
            })}
          </div>
        </div>
        <div className={style.reviewNow}>
          <a
            href="https://g.page/r/CV3qkQ3EWmOwEBE/review "
            target="_blank"
            rel="noreferrer"
          >
            Please Drop a Review
          </a>
        </div>
      </div>
    </section>
  );
}
