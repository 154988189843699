module.exports = [
  {
    name: "Journalist",
    title: "Mr Adeola",
    feedback:
      "“I’m very happy and excited to be getting my allocation today in just 1month after completing payment. Zylus homes came through and delivered as promised”",
    image: require("../image/TestMO1.png"),
  },
  {
    name: "Pastor Daniel",
    title: "Trader",
    feedback:
      "Tiwa Garden City is a wonderful place to have a plot in and coming here to see it myself is like a dream come through for everyone who intends to grow and be wealthy. Zylus homes thank you for giving me the opportunity to secure a plot here.",
    image: require("../image/TestMO2.png"),
  },
  {
    name: "Adebakin Temidayo",
    title: "Trader",
    feedback:
      "“It has been amazing doing business with zylus homes unlike other firms that don’t get allocated on time. Zylus has made every process seamless from payment to allocation. Thank you Zylus for making my dream come through within 1 month”",
    image: require("../image/TestMO3.png"),
  },
];
