import React, { useEffect } from "react";
import blogStyle from "./Blog1.module.scss";
import tiwaiMirn2 from "../../../../image/Team1.png";
import thankgiven1 from "../../../../image/Team2.png";

function PioneeringInternational(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={blogStyle.holdALl}>
      <div>
        <div className={blogStyle.AllCategory}>
          <div className={blogStyle.AllCategory2}>
            <div>
              <div>
                <h3>
                  Zylus Homes: Pioneering International Real Estate Excellence
                  in Nigeria
                </h3>
              </div>
              <div>
                <p className={blogStyle.Developtheskills}>
                  In the ever-evolving landscape of real estate, few companies
                  manage to distinguish themselves with as much impact and
                  innovation as Zylus Homes. As one of the leading international
                  real estate companies in Nigeria, Zylus Homes has become
                  synonymous with quality, trust, and groundbreaking
                  developments that not only cater to local demands but also
                  meet global standards.
                </p>
              </div>
              <div className={blogStyle.HousingPublished}>
                <span className={blogStyle.HousingPublished2}>Housing</span>
                <span className={blogStyle.HousingPublished3}>Published</span>
              </div>
              {/* <button className={blogStyle.learnMore}>Read More</button> */}
              <div className={blogStyle.ADMINMARCH}>
                <span>ADMIN. July 09, 2024</span>
              </div>
            </div>
          </div>
          <div className={blogStyle.Rectangle123}>
            <img
              src={tiwaiMirn2}
              alt="Ibeju Lekki"
              style={{ height: "300px" }}
            />
            <img
              src={thankgiven1}
              alt="Ibeju Lekki"
              style={{ height: "300px" }}
            />
          </div>
        </div>

        <div className={blogStyle.AllCategory}>
          <div className={blogStyle.AllCategory2}>
            {/* <div className={blogStyle.AllIbejuLekki1Pro}>
              <div className={blogStyle.AllIbejuLekki}>
                <p>
                  The grand opening ceremony, held on Jan 31, 2024, was a
                  testament to Zylus Group's unwavering commitment to pushing
                  boundaries and fostering a culture of ingenuity. Distinguished
                  guests, industry leaders, and esteemed clients from around the
                  globe gathered to commemorate this momentous occasion.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <p>
                  Upon arrival, attendees were greeted by a breathtaking
                  architectural marvel, epitomizing modernity and
                  sophistication. The sleek design, characterized by expansive
                  glass facades and contemporary interiors, mirrors Zylus
                  Group's forward-thinking ethos.
                </p>
              </div>
            </div> */}

            <div className={blogStyle.AllIbejuLekki1Pro}>
              <div className={blogStyle.AllIbejuLekki}>
                <h3>A Visionary Beginning:</h3>
                <p>
                  Founded with a visionary approach to redefine real estate in
                  Nigeria, Zylus Homes has consistently pursued excellence. The
                  company's mission has been to provide luxury, comfort, and
                  affordability in all their projects, ensuring that every
                  Nigerian can achieve their dream of owning a home. By focusing
                  on sustainable development, innovative designs, and customer
                  satisfaction, Zylus Homes has carved out a significant niche
                  in the highly competitive real estate market.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Diverse Portfolio of Projects:</h3>
                <p>
                  Zylus Homes boasts an impressive portfolio that spans
                  residential, commercial, and mixed-use developments. Their
                  residential projects are particularly noteworthy, offering a
                  range of options from luxurious villas to affordable housing
                  units. Each project is meticulously planned and executed,
                  reflecting the company’s commitment to quality and innovation.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Residential Estates:</h3>
                <p>
                  Zylus Homes' residential estates are designed to provide a
                  harmonious blend of comfort, security, and modern amenities.
                  These estates feature beautifully landscaped environments,
                  state-of-the-art facilities, and strategic locations that make
                  them ideal for families and individuals seeking a serene
                  living experience.
                </p>
              </div>
              <div className={blogStyle.AllIbejuLekki}>
                <h3>Commercial Developments:</h3>
                <p>
                  Understanding the importance of business-friendly
                  environments, Zylus Homes has developed several commercial
                  properties that cater to various business needs. These
                  properties are equipped with cutting-edge facilities and are
                  strategically located to ensure accessibility and convenience.
                </p>
              </div>
              <div className={blogStyle.AllIbejuLekki}>
                <h3>Mixed-Use Developments:</h3>
                <p>
                  Zylus Homes has also ventured into mixed-use developments that
                  combine residential, commercial, and recreational spaces.
                  These projects are designed to create self-sustaining
                  communities where residents can live, work, and play in close
                  proximity.
                </p>
              </div>
              <div className={blogStyle.AllIbejuLekki}>
                <h3>Innovation and Sustainability:</h3>
                <p>
                  One of the hallmarks of Zylus Homes is their commitment to
                  innovation and sustainability. The company employs the latest
                  construction technologies and environmentally friendly
                  practices to ensure their projects are not only modern but
                  also sustainable. This approach not only reduces the
                  environmental impact but also enhances the quality of life for
                  residents.
                </p>
                <p>
                  Zylus Homes has integrated green spaces, energy-efficient
                  systems, and sustainable materials into their projects. These
                  initiatives demonstrate their dedication to creating
                  eco-friendly communities that can withstand the test of time.
                </p>
              </div>
              <div className={blogStyle.AllIbejuLekki}>
                <h3>Customer-Centric Approach :</h3>
                <p>
                  Zylus Homes prides itself on its customer-centric approach.
                  The company understands that buying a home is one of the most
                  significant investments a person can make. Therefore, they
                  strive to provide exceptional customer service and support
                  throughout the entire process, from inquiry to purchase and
                  beyond.
                </p>
                <p>
                  Their team of experienced professionals is always ready to
                  assist clients with personalized services, ensuring that every
                  customer finds a property that meets their unique needs and
                  preferences. This dedication to customer satisfaction has
                  earned Zylus Homes a loyal clientele and numerous accolades
                  within the industry.
                </p>
              </div>
              <div className={blogStyle.AllIbejuLekki}>
                <h3>Expanding Horizons :</h3>
                <p>
                  As an international real estate company, Zylus Homes is not
                  confined to Nigeria alone. They have ambitious plans to expand
                  their footprint across Africa and beyond, bringing their
                  expertise and innovative approach to new markets. This
                  expansion is a testament to their vision of becoming a global
                  leader in real estate development.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Conclusion :</h3>
                <p>
                  Zylus Homes stands out as a beacon of excellence in the
                  Nigerian real estate sector. Their unwavering commitment to
                  quality, innovation, and customer satisfaction has positioned
                  them as one of the foremost international real estate
                  companies in the country. With a diverse portfolio of projects
                  and a forward-thinking approach, Zylus Homes continues to set
                  new standards in the industry, making them a trusted name for
                  both local and international clients.
                </p>
                <p>
                  Whether you are looking for a luxurious villa, an affordable
                  home, or a strategic commercial property, Zylus Homes offers
                  solutions that cater to diverse needs, ensuring a brighter
                  future for the Nigerian real estate market and beyond.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PioneeringInternational;
