import React, { useState, useEffect } from 'react';
import { IoCall } from 'react-icons/io5';
import BODStyle from "./BOD.module.scss";
import BoardofDirectors from '../../../DB/BoardofDirectors';
import { Modal } from "react-bootstrap";

function BOD(props) {
    const [num, setNum] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (index) => {
        setNum(index);
        setShow(true);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
      <section className={BODStyle.holdALl}>
        <div>
          <div
            className={BODStyle.holdAllTeamText}
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={BODStyle.holdAllTeamText1}>
              <h5>The Board </h5>
              {/* <h3>Meet the Board Of Directors at Zylus Homes</h3> */}
              <p>
                Meet the visionary leaders guiding our journey to redefine
                modern living
              </p>
              <p>
                Introducing the distinguished Board of Directors at Zylus Homes
                – a team of accomplished professionals dedicated to steering our
                vision and growth. With diverse expertise and strategic insight,
                they provide strong leadership and governance, ensuring we
                uphold our values of integrity, innovation, and excellence.
              </p>
            </div>
          </div>

          <div className={BODStyle.holdAllExecutiveDirectors}>
            <div className={BODStyle.holdAllExecutiveDirectors2}>
              {/* <h4>Executive Directors</h4> */}
              <div className={BODStyle.holdAllProfileKoko}>
                {BoardofDirectors.map((item, index) => {
                  console.log(item.name);
                  return (
                    <div
                      className={BODStyle.holdAllProfileKoko2}
                      key={index}
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="600"
                    >
                      <div className={BODStyle.holdImage}>
                        <img src={item.image} alt="Team1" />
                      </div>
                      <div className={BODStyle.holdText}>
                        <div className={BODStyle.holdText2}>
                          <h5>{item.name}</h5>
                          <p>{item.position}</p>
                          <p>{item.member}</p>
                          <button
                            onClick={() => {
                              handleShow(index);
                            }}
                          >
                            View Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={BODStyle.holdAnytime}>
          <div>
            <h1 className={BODStyle.Reach}>You Can Reach Us Anytime</h1>
            <p className={BODStyle.question}>
              Have a question or doubt about our property?
            </p>
          </div>
          <button className={BODStyle.callUs}>
            <IoCall />
            Call us
          </button>
        </div>
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Body className={BODStyle.modalBodyV}>
            <div className={BODStyle.NonBoardofDirectors}>
              <div className={BODStyle.NonBoardofDirectors2}>
                <img src={BoardofDirectors[num].image} alt="Team1" />
              </div>
              <div className={BODStyle.NonBoardofDirectors3}>
                <p>{BoardofDirectors[num].profile1}</p>
                <p>{BoardofDirectors[num].profile2}</p>
                <p>{BoardofDirectors[num].profile3}</p>
                <p>{BoardofDirectors[num].profile4}</p>
                <p>{BoardofDirectors[num].profile5}</p>
                <p>{BoardofDirectors[num].profile6}</p>
                <p>{BoardofDirectors[num].profile7}</p>
                <p>{BoardofDirectors[num].profile8}</p>
                <p>{BoardofDirectors[num].profile9}</p>
                <p>{BoardofDirectors[num].profile10}</p>
                <p>{BoardofDirectors[num].profile11}</p>
                <p>{BoardofDirectors[num].profile12}</p>
                <btton onClick={handleClose} className={BODStyle.CloseTas}>
                  Close
                </btton>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    );
}

export default BOD;