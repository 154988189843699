import React, { useState, useEffect } from "react";
// import Logo from "../../image/ZYLUS NEW Logo 1.png";
import Logo from "../../image/XMAS LOGO.png";
import { NavLink } from "react-router-dom";
import menuStyle from "./Menu.module.scss";
import { IoMenu } from "react-icons/io5";

function Menu(props) {
  const [navControl, stenavControl] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const buger = () => {
    stenavControl(!navControl);
  };

  const buger2 = () => {
    if (isMobile) {
      stenavControl(false);
    } else {
      stenavControl(true);
    }
  };
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  useEffect(() => {
    if (isMobile) {
      stenavControl(false);
    } else {
      stenavControl(true);
    }
  }, []);

  return (
    <section>
      <div
        className={menuStyle.holdAll}
        data-aos="slide-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <NavLink
          style={({ isActive }) => {
            return isActive ? { color: "#FFCC29", fontWeight: 600 } : {};
          }}
          to={"/"}
          onClick={buger2}
        >
          <div className={menuStyle.logoDivs}>
            <img src={Logo} alt="Logo" className={menuStyle.holdLogo} />
          </div>
        </NavLink>
        {/* <div className={menuStyle.logoDivs}>
          <img src={Logo} alt="Logo" className={menuStyle.holdLogo} />
        </div> */}
        <div className={menuStyle.lineMenu}>
          <IoMenu className={menuStyle.navMenu} onClick={buger} />
          <ul className={navControl ? menuStyle.holdList : menuStyle.holdList2}>
            <li>
              <NavLink
                style={({ isActive }) => {
                  return isActive ? { color: "#FFCC29", fontWeight: 600 } : {};
                }}
                to={"/"}
                onClick={buger2}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                style={({ isActive }) => {
                  return isActive ? { color: "#FFCC29", fontWeight: 600 } : {};
                }}
                to={"/products"}
                onClick={buger2}
              >
                Products
              </NavLink>
            </li>
            <li>
              Media
              <ul className={menuStyle.holdSubList}>
                {/* <li>
                                    <NavLink style={({ isActive }) => {
                                        return isActive ? { color: "#FFCC29" } : {}
                                    }} to={"/gallery"}>
                                        Gallery
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink style={({ isActive }) => {
                                        return isActive ? { color: "#FFCC29" } : {}
                                    }} to={"/photo"}>
                                        Photo
                                    </NavLink>
                                </li> */}

                <li>
                  {/* <a href="https://blog.zylushomes.com/" target="_blank" onClick={buger2}>
                    Blog
                  </a> */}
                  <NavLink
                    style={({ isActive }) => {
                      return isActive
                        ? { color: "#FFCC29", fontWeight: 600 }
                        : {};
                    }}
                    to={"/blog"}
                    onClick={buger2}
                  >
                    Blog
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={({ isActive }) => {
                      return isActive
                        ? { color: "#FFCC29", fontWeight: 600 }
                        : {};
                    }}
                    to={"/testimonial"}
                    onClick={buger2}
                  >
                    Testimonial
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                style={({ isActive }) => {
                  return isActive ? { color: "#FFCC29", fontWeight: 600 } : {};
                }}
                to={"/"}
                onClick={buger2}
              >
                <div className={menuStyle.logoDiv}>
                  <img src={Logo} alt="Logo" className={menuStyle.holdLogo} />
                </div>
              </NavLink>
            </li>
            <li>
              About
              <ul className={menuStyle.holdSubList}>
                <li>
                  <NavLink
                    style={({ isActive }) => {
                      return isActive
                        ? { color: "#FFCC29", fontWeight: 600 }
                        : {};
                    }}
                    to={"/who-are-we"}
                    onClick={buger2}
                  >
                    Who Are We
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={({ isActive }) => {
                      return isActive
                        ? { color: "#FFCC29", fontWeight: 600 }
                        : {};
                    }}
                    to={"/board-of-directors"}
                    onClick={buger2}
                  >
                    Board of Directors
                  </NavLink>
                </li>
                {/* <li>
                                    <NavLink style={({ isActive }) => {
                                        return isActive ? { color: "#FFCC29" } : {}
                                    }} to={"/executive-management"}>
                                        Executive Management
                                    </NavLink>
                                </li> */}
                <li>
                  <NavLink
                    style={({ isActive }) => {
                      return isActive
                        ? { color: "#FFCC29", fontWeight: 600 }
                        : {};
                    }}
                    to={"/non-executive-management"}
                    onClick={buger2}
                  >
                    Management Team
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    style={({ isActive }) => {
                      return isActive
                        ? { color: "#FFCC29", fontWeight: 600 }
                        : {};
                    }}
                    to={"/certifications"}
                    onClick={buger2}
                  >
                    Certifications
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={({ isActive }) => {
                      return isActive
                        ? { color: "#FFCC29", fontWeight: 600 }
                        : {};
                    }}
                    to={"/faq"}
                    onClick={buger2}
                  >
                    FAQ
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                style={({ isActive }) => {
                  return isActive ? { color: "#FFCC29", fontWeight: 600 } : {};
                }}
                to={"/contact"}
                onClick={buger2}
              >
                Contact
              </NavLink>
            </li>
            <li>
              <a href="tel:07034100972">
                <button className={menuStyle.callUS} onClick={buger2}>
                  Call us
                </button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Menu;
