import React, { useState, useEffect } from 'react';
import blogStyle from "./Blog.module.scss";
import Rectangle123 from "../../../image/Rectangle 123.png";
import moneytime from "../../../image/moneytime.jpeg";
import ibejulekki2 from "../../../image/ibeju lekki 2.jpg";
import thankgiven1 from "../../../image/horeImage4.jpg";
import FuelSubsideyRemoval from "../../../image/FuelSubsideyRemoval.png";
import LekkiPhoto from "../../../image/Rectangle 105.png";
import ArizonExplore from "../../../image/ArizonExplore.png";
import zyluslo from "../../../image/zyluslo.png";
import zylusPlace1 from "../../../image/zylus house place 3.jpg";
import { BsTwitter, BsInstagram, BsFacebook } from "react-icons/bs";
import { Link } from "react-router-dom";

function Blog(props) {
  const [count, setCount] = useState(0);

  const counts = (index) => {
    setCount(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={blogStyle.holdALl}>
      <div>
        <div className={blogStyle.holdOurBlog}>
          <div>
            <p>Our Blog</p>
            <h4>Real Estate Insights and Topics from our Desk</h4>
          </div>
        </div>
        <div className={blogStyle.AllCategory}>
          <div className={blogStyle.AllCategory2}>
            <div>
              <img
                src={ArizonExplore}
                alt="Rectangle123"
                className={blogStyle.Rectangle123}
              />
            </div>
            <div>
              <div className={blogStyle.HousingPublished}>
                <span className={blogStyle.HousingPublished2}>Housing</span>
                <span className={blogStyle.HousingPublished3}>Published</span>
              </div>
              <div>
                <h1>
                  Arizon Estate — Unlocking the Best Real Estate Investment in
                  “The New Lagos”
                </h1>
              </div>
              <div>
                <p className={blogStyle.Developtheskills}>
                  Welcome to the world of Arizon Estate, the ultimate investment
                  opportunity within the thriving landscape of “The New Lagos.”
                  In this blog post, we will explore why Arizon Estate stands
                  out as the perfect investment option, offering unparalleled
                  advantages to those seeking the best real estate property in
                  this vibrant city.
                </p>
              </div>
              <Link to={"/blog2"}>
                <button className={blogStyle.learnMore}>Read More</button>
              </Link>
              <div className={blogStyle.ADMINMARCH}>
                <span>ADMIN. July 19, 2023</span>
              </div>
            </div>
          </div>
        </div>
        <div className={blogStyle.holdSelect}>
          <div>
            <button
              className={
                count === 0 ? blogStyle.AllCategories : blogStyle.AllCategories2
              }
              onClick={() => {
                counts(0);
              }}
            >
              All Categories
            </button>
            <button
              className={
                count === 1 ? blogStyle.AllCategories : blogStyle.AllCategories2
              }
              onClick={() => {
                counts(1);
              }}
            >
              Real Estate
            </button>
            <button
              className={
                count === 2 ? blogStyle.AllCategories : blogStyle.AllCategories2
              }
              onClick={() => {
                counts(2);
              }}
            >
              Interior Designs
            </button>
            <button
              className={
                count === 3 ? blogStyle.AllCategories : blogStyle.AllCategories2
              }
              onClick={() => {
                counts(3);
              }}
            >
              Housing
            </button>
            <button
              className={
                count === 4 ? blogStyle.AllCategories : blogStyle.AllCategories2
              }
              onClick={() => {
                counts(4);
              }}
            >
              Real Estate Investment
            </button>
            <button
              className={
                count === 5 ? blogStyle.AllCategories : blogStyle.AllCategories2
              }
              onClick={() => {
                counts(5);
              }}
            >
              Architecture
            </button>
          </div>
        </div>

        <div className={blogStyle.holdEstateHouses}>
          <div className={blogStyle.holdEstateHouses2}>
            <div className={blogStyle.holdEstateHousesKoko}>
              {/* <Link to={"/zylus-group-international"}>
                <div className={blogStyle.holdEstateHousesKoko3}>
                  <img src={zylusgroups} alt="Zylus Group International" />
                  <div>
                    <h5>Housing</h5>
                    <p>
                      Zylus Group International a fast-growing and
                      internationally recognized
                    </p>
                    <text>ADMIN. June 19, 2024</text>
                  </div>
                </div>
              </Link> */}

              <Link
                to={
                  "/pioneering-international-real-estate-excellence-in-nigeria"
                }
              >
                <div className={blogStyle.holdEstateHousesKoko3}>
                  <img src={zyluslo} alt="Zylus Homes" />
                  <div>
                    <h5>Housing</h5>
                    <p>
                      Zylus Homes: Pioneering International Real Estate
                      Excellence in Nigeria
                    </p>
                    <text>ADMIN. July 09, 2024</text>
                  </div>
                </div>
              </Link>

              <Link to={"/lagos-real-estate"}>
                <div className={blogStyle.holdEstateHousesKoko3}>
                  <img src={LekkiPhoto} alt="Lekki Real Estate" />
                  <div>
                    <h5>Housing</h5>
                    <p>
                      Beyond the Horizon: Exploring the Untapped Potential of
                      Lagos Real Estate
                    </p>
                    <text>ADMIN. May 03, 2024</text>
                  </div>
                </div>
              </Link>

              <Link to={"/estates-in-ibeju-lekki"}>
                <div className={blogStyle.holdEstateHousesKoko3}>
                  <img src={ibejulekki2} alt="Ibeju Lekki" />
                  <div>
                    <h5>Housing</h5>
                    <p>
                      Discover Unmatched Luxury and Investment Potential:
                      Estates in Ibeju Lekki
                    </p>
                    <text>ADMIN. April 24, 2024</text>
                  </div>
                </div>
              </Link>

              <Link to={"/blog1"}>
                <div className={blogStyle.holdEstateHousesKoko3}>
                  <img src={Rectangle123} alt="house1" />
                  <div>
                    <h5>Housing</h5>
                    <p>
                      Exploring the Lucrative Real Estate Investment
                      Opportunities in the New Lagos - Ibeju Lekki
                    </p>
                    <text>ADMIN. JULY 20, 2023</text>
                  </div>
                </div>
              </Link>

              <Link to={"/blog3"}>
                <div className={blogStyle.holdEstateHousesKoko3}>
                  <img src={moneytime} alt="moneytime" />
                  <div>
                    <h5>Housing</h5>
                    <p>
                      How to Make Money from Real Estate as a 9-5 Worker: A
                      Step-by-Step Guide
                    </p>
                    <text>ADMIN. AUGUST 03, 2023</text>
                  </div>
                </div>
              </Link>

              <Link to={"/blog4"}>
                <div className={blogStyle.holdEstateHousesKoko3}>
                  <img src={FuelSubsideyRemoval} alt="FuelSubsideyRemoval" />
                  <div>
                    <h5>Fuel Subsidey</h5>
                    <p>
                      Fuel Subsidy Removal: A Call to Increase Your Revenue
                      Strategically
                    </p>
                    <text>ADMIN. AUGUST 11, 2023</text>
                  </div>
                </div>
              </Link>

              <Link to={"/blog5"}>
                <div className={blogStyle.holdEstateHousesKoko3}>
                  <img src={thankgiven1} alt="house3" />
                  <div>
                    <h5>Celebration</h5>
                    <p>
                      Ooni of Ife unveils Model for Zylus New Estate, Arizon
                      Estate at Annual Thanksgiving and Award
                    </p>
                    <text>ADMIN. Jan 20, 2024</text>
                  </div>
                </div>
              </Link>

              <Link to={"/blog6"}>
                <div className={blogStyle.holdEstateHousesKoko3}>
                  <img src={zylusPlace1} alt="house4" />
                  <div>
                    <h5>Grand Opening</h5>
                    <p>
                      Zylus Group International Unveils Grand Opening of New
                      Headquarters
                    </p>
                    <text>ADMIN. Jan 31, 2024</text>
                  </div>
                </div>
              </Link>
            </div>
            <div className={blogStyle.newLetter}>
              <h1 className={blogStyle.NEWSLETTER}>NEWSLETTER</h1>
              <div className={blogStyle.holdSubscribe}>
                <input type="search" placeholder="Enter Your Email" />
                <button>Subscribe</button>
              </div>
              <h4 className={blogStyle.FOLLOWUS}>FOLLOW US</h4>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <a
                  href="https://twitter.com/zylushomes?s=21&t=rNVH45wRNUI2fNaDLY4VYA"
                  target="_blank"
                  rel="noreferrer"
                  className={blogStyle.Twitter}
                >
                  <BsTwitter /> Twitter
                </a>
                <a
                  href="https://www.instagram.com/zylushomes?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                  target="_blank"
                  rel="noreferrer"
                  className={blogStyle.Twitter}
                >
                  <BsInstagram /> Instagram
                </a>
                <a
                  href="https://web.facebook.com/zylushomesnproperty"
                  target="_blank"
                  rel="noreferrer"
                  className={blogStyle.Twitter}
                >
                  <BsFacebook /> Facebook
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blog;