import React, { useEffect } from "react";
import blogStyle from "./Blog1.module.scss";
import arizonDetails3 from "../../../../image/moneyBlog1.jpeg";
import arizonDetails4 from "../../../../image/moneyBlog2.jpeg";

function Blog3(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={blogStyle.holdALl}>
      <div>
        <div className={blogStyle.AllCategory}>
          <div className={blogStyle.AllCategory2}>
            <div>
              <div>
                <h3>
                  How to Make Money from Real Estate as a 9-5 Worker: A
                  Step-by-Step Guide
                </h3>
              </div>
              <div>
                <p className={blogStyle.Developtheskills}>
                  Are you a 9-5 worker who dreams of making money from real
                  estate? You might be wondering if it's possible to juggle your
                  day job and build a profitable real estate portfolio. The good
                  news is, with the right approach and careful planning, you can
                  generate income from real estate investments while maintaining
                  your regular job. In this article, we will explore effective
                  strategies to help you make money from real estate as a 9-5
                  worker.
                </p>
              </div>
              <div className={blogStyle.HousingPublished}>
                <span className={blogStyle.HousingPublished2}>Housing</span>
                <span className={blogStyle.HousingPublished3}>Published</span>
              </div>
              {/* <button className={blogStyle.learnMore}>Read More</button> */}
              <div className={blogStyle.ADMINMARCH}>
                <span>ADMIN. MARCH 20, 2023</span>
              </div>
            </div>
          </div>
          <div className={blogStyle.Rectangle123}>
            <img
              src={arizonDetails3}
              alt="Rectangle123"
              style={{ height: "300px" }}
            />
            <img
              src={arizonDetails4}
              alt="Rectangle123"
              style={{ height: "300px" }}
            />
          </div>
        </div>

        <div className={blogStyle.AllCategory}>
          <div className={blogStyle.AllCategory2}>
            <div className={blogStyle.AllIbejuLekki1Pro}>
              <div className={blogStyle.AllIbejuLekki}>
                <h3>Educate yourself:</h3>
                <p>
                  Start by learning about real estate investing. Read books,
                  attend seminars, take online courses, and join local real
                  estate investment clubs to gain knowledge about different
                  investment strategies, financing options, market trends, and
                  property analysis.
                </p>
              </div>
              <div className={blogStyle.AllIbejuLekki}>
                <h3>Set clear goals:</h3>
                <p>
                  Determine your financial goals and what you want to achieve
                  through real estate investing. Do you want to generate passive
                  income, build equity, or flip properties for quick profits?
                  Setting specific goals will help you focus your efforts.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Create a financial plan:</h3>
                <p>
                  Assess your current financial situation and create a budget.
                  Determine how much money you can allocate towards real estate
                  investing, including saving for down payments, repairs, and
                  other expenses. Consider speaking with a financial advisor to
                  help you develop a plan that aligns with your goals.
                </p>
              </div>
            </div>

            <div className={blogStyle.AllIbejuLekki1Pro}>
              <div className={blogStyle.AllIbejuLekki}>
                <h3>Start small:</h3>
                <p>
                  Begin by investing in properties that require less time and
                  effort, such as single-family homes or small multifamily
                  properties. These types of properties are often easier to
                  manage while working a full-time job. Look for properties in
                  affordable neighborhoods with good potential for rental income
                  or future appreciation.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Research and analyze deals:</h3>
                <p>
                  Thoroughly research the real estate market in your target
                  area. Analyze potential investment properties by considering
                  factors like location, rental income potential, property
                  condition, and market trends. Use online tools, real estate
                  websites, and local real estate agents to gather information
                  and perform comparative market analyses.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Build a reliable team:</h3>
                <p>
                  Surround yourself with a team of professionals who can support
                  your real estate ventures. This may include a real estate
                  agent, a property manager, a real estate attorney,
                  contractors, and a mortgage broker. Having a reliable team
                  will help you navigate the challenges of real estate investing
                  while working a full-time job.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Leverage technology and automation:</h3>
                <p>
                  Use technology and automation tools to streamline your real
                  estate activities. For example, you can use property
                  management software to handle tenant communication, rent
                  collection, and maintenance requests. Additionally, consider
                  online platforms for marketing and finding tenants.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Time management:</h3>
                <p>
                  Effectively managing your time is crucial when investing in
                  real estate alongside a 9-5 job. Set aside specific time slots
                  for real estate activities, such as property research,
                  networking, and property inspections. Prioritize your tasks
                  and use your time efficiently to maximize productivity.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Explore financing options:</h3>
                <p>
                  Research different financing options available to you, such as
                  traditional mortgages, private lenders, or partnerships.
                  Evaluate the pros and cons of each option to determine what
                  works best for your situation. Consider working on improving
                  your credit score to qualify for better financing terms.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Network and build relationships:</h3>
                <p>
                  Attend real estate investment meetings, join online forums,
                  and network with other investors. Building relationships with
                  experienced investors, lenders, and industry professionals can
                  provide valuable insights, mentorship, and potential
                  investment opportunities.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Scale gradually:</h3>
                <p>
                  As you gain experience and confidence, you can gradually scale
                  your real estate portfolio. Reinvest your profits into
                  acquiring more properties or explore other strategies like
                  house hacking, where you live in one unit of a multifamily
                  property and rent out the other units.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                {/* <h3>Scale gradually:</h3> */}
                <p>
                  Remember, real estate investing requires patience,
                  persistence, and due diligence. It's important to thoroughly
                  research each investment opportunity and make informed
                  decisions. As you gain more experience and grow your
                  portfolio, you may be able to transition from your 9-5 job to
                  full-time real estate investing if that is your long-term
                  goal.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blog3;
