import React, { useEffect } from "react";
import blogStyle from "./Blog1.module.scss";
import arizonDetails4 from "../../../../image/zylus house place 1.jpg";
import arizonDetails3 from "../../../../image/zylus house place 2.jpg";

function Blog6(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={blogStyle.holdALl}>
      <div>
        <div className={blogStyle.AllCategory}>
          <div className={blogStyle.AllCategory2}>
            <div>
              <div>
                <h3>
                  Zylus Group International Unveils Grand Opening of New
                  Headquarters
                </h3>
              </div>
              <div>
                <p className={blogStyle.Developtheskills}>
                  Zylus Group International, a trailblazer in the realm of Real
                  Estate and Investment, proudly announces the inauguration of
                  its new global headquarters. Nestled in the heart of 4A Omon
                  Ebhomenye Street, Off Admiralty Way, Lekki Phase 1, Lagos,
                  Nigeria., the state-of-the-art facility marks a significant
                  milestone in the company's journey towards excellence and
                  expansion.
                </p>
              </div>
              <div className={blogStyle.HousingPublished}>
                <span className={blogStyle.HousingPublished2}>Celebration</span>
                <span className={blogStyle.HousingPublished3}>Published</span>
              </div>
              {/* <button className={blogStyle.learnMore}>Read More</button> */}
              <div className={blogStyle.ADMINMARCH}>
                <span>ADMIN. Jan 31, 2024</span>
              </div>
            </div>
          </div>
          <div className={blogStyle.Rectangle123}>
            <img
              src={arizonDetails3}
              alt="Rectangle123"
              style={{ height: "300px" }}
            />
            <img
              src={arizonDetails4}
              alt="Rectangle123"
              style={{ height: "300px" }}
            />
          </div>
        </div>

        <div className={blogStyle.AllCategory}>
          <div className={blogStyle.AllCategory2}>
            <div className={blogStyle.AllIbejuLekki1Pro}>
              <div className={blogStyle.AllIbejuLekki}>
                <p>
                  The grand opening ceremony, held on Jan 31, 2024, was a
                  testament to Zylus Group's unwavering commitment to pushing
                  boundaries and fostering a culture of ingenuity. Distinguished
                  guests, industry leaders, and esteemed clients from around the
                  globe gathered to commemorate this momentous occasion.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <p>
                  Upon arrival, attendees were greeted by a breathtaking
                  architectural marvel, epitomizing modernity and
                  sophistication. The sleek design, characterized by expansive
                  glass facades and contemporary interiors, mirrors Zylus
                  Group's forward-thinking ethos.
                </p>
              </div>
            </div>

            <div className={blogStyle.AllIbejuLekki1Pro}>
              <div className={blogStyle.AllIbejuLekki}>
                {/* <h3>Start small:</h3> */}
                <p>
                  The event commenced with a ribbon-cutting ceremony,
                  symbolizing the official unveiling of the headquarters. Dr.
                  Oluwatosin Olatujoye, accompanied by key executives, had the
                  honor of inaugurating the premises, underscoring the company's
                  dedication to leading by example.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                {/* <h3>Research and analyze deals:</h3> */}
                <p>
                  Guests were then treated to an immersive tour of the facility,
                  guided by Zylus Group's passionate team members. From
                  collaborative workspaces equipped with cutting-edge technology
                  to innovation buzzing with creative energy, every corner
                  exuded an atmosphere of inspiration and possibility.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                {/* <h3>Research and analyze deals:</h3> */}
                <p>
                  Throughout the festivities, attendees had the opportunity to
                  engage in stimulating discussions and networking sessions,
                  fostering meaningful connections and alliances within the
                  industry. Interactive demonstrations showcased Zylus Group's
                  latest breakthroughs and solutions, igniting excitement and
                  anticipation among guests.
                </p>
              </div>
              <div className={blogStyle.AllIbejuLekki}>
                {/* <h3>Research and analyze deals:</h3> */}
                <p>
                  In addition to celebrating the new headquarters, the event
                  served as a platform to express gratitude to Zylus Group's
                  valued partners, clients, and employees whose unwavering
                  support has been instrumental in fueling the company's growth
                  and success.
                </p>
              </div>
              <div className={blogStyle.AllIbejuLekki}>
                {/* <h3>Research and analyze deals:</h3> */}
                <p>
                  As the day drew to a close, amidst a backdrop of jubilation
                  and optimism, Dr. Oluwatosin Olatujoye delivered a heartfelt
                  address, expressing gratitude for the collective efforts that
                  brought the vision of the new headquarters to fruition.
                  Emphasizing Zylus Group's commitment to driving innovation and
                  making a positive impact on a global scale, Dr. Oluwatosin
                  Olatujoye outlined an ambitious roadmap for the future,
                  promising even greater achievements on the horizon.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                {/* <h3>Research and analyze deals:</h3> */}
                <p>
                  The grand opening of Zylus Group International's new
                  headquarters stands as a testament to the company's unwavering
                  dedication to excellence, innovation, and collaboration. With
                  a bold vision and a steadfast resolve, Zylus Group embarks on
                  the next chapter of its journey, poised to redefine the
                  boundaries of possibility and shape the future of Real Estate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blog6;
