import React, { useEffect } from "react";
import blogStyle from "./Blog1.module.scss";
import tiwaiMirn2 from "../../../../image/Team1.png";
import thankgiven1 from "../../../../image/Team2.png";
import { Link } from "react-router-dom";

function ZylusGroupInternational(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={blogStyle.holdALl}>
      <div>
        <div className={blogStyle.AllCategory}>
          <div className={blogStyle.AllCategory2}>
            <div>
              <div>
                <h3>
                  Zylus Group International a fast-growing and internationally
                  recognized Real Estate Development.
                </h3>
              </div>
              <div>
                <p className={blogStyle.Developtheskills}>
                  Zylus Group International is a fast-growing, privately owned,
                  and internationally recognized business capital organization
                  with interests in Real Estate Development, Human Capital
                  Development, Agriculture, Designs and Printing, Media,
                  Transport and Logistics, Travel and Tours, and many more.
                </p>
              </div>
              <div className={blogStyle.HousingPublished}>
                <span className={blogStyle.HousingPublished2}>Housing</span>
                <span className={blogStyle.HousingPublished3}>Published</span>
              </div>
              {/* <button className={blogStyle.learnMore}>Read More</button> */}
              <div className={blogStyle.ADMINMARCH}>
                <span>ADMIN. June 19, 2024</span>
              </div>
            </div>
          </div>
          <div className={blogStyle.Rectangle123}>
            <img
              src={tiwaiMirn2}
              alt="Ibeju Lekki"
              style={{ height: "300px" }}
            />
            <img
              src={thankgiven1}
              alt="Ibeju Lekki"
              style={{ height: "300px" }}
            />
          </div>
        </div>

        <div className={blogStyle.AllCategory}>
          <div className={blogStyle.AllCategory2}>
            {/* <div className={blogStyle.AllIbejuLekki1Pro}>
              <div className={blogStyle.AllIbejuLekki}>
                <p>
                  The grand opening ceremony, held on Jan 31, 2024, was a
                  testament to Zylus Group's unwavering commitment to pushing
                  boundaries and fostering a culture of ingenuity. Distinguished
                  guests, industry leaders, and esteemed clients from around the
                  globe gathered to commemorate this momentous occasion.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <p>
                  Upon arrival, attendees were greeted by a breathtaking
                  architectural marvel, epitomizing modernity and
                  sophistication. The sleek design, characterized by expansive
                  glass facades and contemporary interiors, mirrors Zylus
                  Group's forward-thinking ethos.
                </p>
              </div>
            </div> */}

            <div className={blogStyle.AllIbejuLekki1Pro}>
              <div className={blogStyle.AllIbejuLekki}>
                <h3>Company Profile:</h3>
                <p>
                  <Link to="/">Zylus Group</Link> is a fast-growing, privately
                  owned, and internationally recognized business capital
                  organization with interests in Real Estate Development, Human
                  Capital Development, Agriculture, Designs and Printing, Media,
                  Transport and Logistics, Travel and Tours, and many more. Our
                  corporate culture speaks Professionalism, Excellence,
                  Integrity, Knowledge, and Service to offering top-notch
                  services to all our partners across all our operational
                  subsidiaries.
                </p>
                <p>
                  The company’s operation is governed and guided by a strict
                  Code of Ethics which sets the pace for its operations,
                  commitment to excellence, and professionalism. With the
                  support of her committed team, Zylus Group International has
                  grown tremendously in revenue, size, and strength whilst
                  maximizing the business potentials of her esteemed clients,
                  both locally and internationally
                </p>
                <p>
                  The company is driven by its mantra: nurturing dreams and
                  building realities, through its commitment to providing
                  solutions to the housing deficit problems faced by Nigerians,
                  empowering Nigerian youths through human capital development.
                </p>
                <p>
                  Zylus Group boasts of owning over 23 estates in several parts
                  of Nigeria and has since evolved into a group since its
                  inception as “Oga for Property” in 2018
                </p>
                <p>
                  Zylus Group is the parent company of over 30 subsidiaries
                  including Zylus Homes and Property Limited, Zylus Transport,
                  and Logistics Limited, Zylus Property Development Limited,
                  Zylus Travels, and Tour Limited, Zylus Designs and Prints
                  Limited, Zylus Media Hub, Zylus Elites Thrift and Credit
                  Co-operative Society, Zylus Farmvest Limited; Zylus Property
                  Management Limited, among many other functioning subsidiaries.
                </p>
                <p>
                  We aim to become one of Africa’s most prominent conglomerates
                  with a mandate to control over 80 independently-financed
                  subsidiaries in five countries worldwide within our first ten
                  years of operation, and currently have a presence in 4 states
                  of the federation, and employs over 8,000 Nigerians in its
                  operations.
                </p>
                <p>
                  We are governed by the general algorithm that every client is
                  number one. In view of this, our value for existing clients,
                  investors, or prospects knows no bound.
                </p>
                <p>
                  Zylus Group’s strategic framework consciously penetrates new
                  markets daily and prides itself on enhancing the tradition of
                  extraordinary services. The company’s constant growth since
                  its creation has proven that her expertise, market skills, and
                  hard work keep satisfying her clients.
                </p>
                <p>
                  Zylus Group is led by Oluwatosin Olatujoye, Group Chief
                  Executive Officer, and Bukola Olatujoye, ED, Admin &
                  Operations, with the support of a qualified and highly
                  motivated management team on a unique model that involves
                  constant development and evolution, Zylus Group International
                  has defiled all odds, disrupted the norm, and set a standard
                  in the Real Estate industry in Nigeria and across Africa.
                </p>
                <p>
                  As a corporate firm, Zylus Group International is registered
                  with the Corporate Affairs Commission of Nigeria (RC 1668319),
                  licensed and regulated by the Lagos State Real Estate
                  Regulatory Authority (LASRERA), Real Estate Developers
                  Association of Nigeria (REDAN), and the Special Control Unit
                  against Anti- Money Laundering (SCUML).
                </p>
                <p>
                  Zylus Group International has been nominated and won several
                  awards including the Real Estate Excellence Award in 2020, the
                  Excellence Award as a 21st-century Real Estate Company in
                  2020, and The Next Bulls Award 2021 in Recognition of Market
                  Leadership and Pace-setting Innovation by Businessday.
                </p>
                <p>
                  Zylus Group International has carried out several Community
                  Social Responsibility initiatives during the first wave of the
                  COVID-19 pandemic and has continued to provide help to the
                  needy since then with its FEED THE 1000 initiative, where we
                  fed over 1000 less privileged, among other initiatives.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Why We Rebranded ?:</h3>
                <p>
                  The number one real estate company firm in Nigeria rebranded
                  from “Oga for property” to Zylus Group International as a
                  result of her expansion.
                </p>
                <p>
                  The company’s operation is governed and guided by a strict
                  Code of Ethics which sets the pace for its operations,
                  commitment to excellence, and professionalism.
                </p>
                <p>
                  The term Zylus is coined from the word Zeal meaning energy.
                  Formed with a group of highly intellectual young
                  professionals, the name Zylus was given because it’s believed
                  that the energetic team has the zeal to do every, many and all
                  things as it relates to the economy.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Real Estate Development :</h3>
                <p>
                  We provide strategic solutions to the housing needs of
                  Nigerians home and abroad and assist the government to solve
                  the housing deficit problems faced by Nigerians. We have over
                  23 estates in developed and developing locations in Lagos,
                  Abuja, Ogun, Enugu.
                </p>
                <p>
                  Our estates carry legal titles, built with the standard
                  materials by world-class experts with professionalism, safety,
                  and satisfaction in mind.
                </p>
              </div>
              <div className={blogStyle.AllIbejuLekki}>
                <h3>Human Capital Devlopement :</h3>
                <p>
                  As a company, we are committed to creating job opportunities,
                  improving the performance, capabilities, and resources of our
                  employees and salesforce. We understand that the growth and
                  productivity of the organization rest on the shoulders of its
                  human resource.
                </p>
                <p>
                  Currently, we employ over 8,000 Nigerians in different states
                  of the federation in our operations. We organize training,
                  seminars, workshops, team-building activities, and on-the-job
                  training for our operations staff and salesforce.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ZylusGroupInternational;
