module.exports = [
  {
    name: "ZYLUS GROUP DOCUMENTARY HD",
    type: "house",
    iframe: "https://www.youtube.com/embed/kRJBlu-BIEQ?si=V9pYniWkrTK-HDhk",
  },
  {
    name: "Lekki Pride II",
    type: "house",
    iframe: "https://www.youtube.com/embed/cATYjVawmZ8?si=SoCz0kfhfi2pYJhv",
  },
  {
    name: "Lekki Pride I",
    type: "house",
    iframe: "https://www.youtube.com/embed/cATYjVawmZ8?si=d9YlFO8ZzEWyrxXN",
  },
  {
    name: "LEKKI PRIDE 1 SITE UPDATE",
    type: "house",
    iframe: "https://www.youtube.com/embed/p6JFw_c2b38?si=GoHvFhXX2glbeDzs",
  },
  {
    name: "HILDA BACI ARIZON ESTATE LAND ALLOCATION",
    type: "land",
    iframe: "https://www.youtube.com/embed/qnRxRuxRW3M?si=rZIb3Acvp1XZMGyE",
  },
  {
    name: "TIWA COMMERCIAL HUB ALLOCATION/TESTIMONIAL",
    type: "land",
    iframe: "https://www.youtube.com/embed/eR63qOMrE58?si=h_DkwiRfMQg9mqpa",
  },
  {
    name: "ARIZON ESTATE ALLOCATION/TESTIMONIAL",
    type: "land",
    iframe: "https://www.youtube.com/embed/xWDbdxTAzsY?si=lXP4kxIrUMn52T18",
  },
  {
    name: "TIWA GARDEN PHASE I ALLOCATION/TESTIMONIAL",
    type: "land",
    iframe: "https://www.youtube.com/embed/GIWxjBs58uA?si=ZMJeoUsnex5U4MR7",
  },
  {
    name: "HILDA BACI X ARIZON ESTATE",
    type: "land",
    iframe: "https://www.youtube.com/embed/3RuYVrEBdAU?si=9BVTLhUPAfwiB86I",
  },
  {
    name: "Arizon Estate Relaunch Testimonial",
    type: "land",
    iframe: "https://www.youtube.com/embed/UwiD9rkcYuc?si=Iz6TxC3dtYD9XVXP",
  },
  {
    name: "SABINUS Visits ARIZON ESTATE IBEJU LEKKI | Pre-launch Price Ends Soon",
    type: "land",
    iframe: "https://www.youtube.com/embed/kZHhJb5SCSY?si=Vtqw-OvgAWajbjiI",
  },
  {
    name: "Arizon Estate Land document presentation to Guinness World Record, Holder Hilda Baci 🤩",
    type: "land",
    iframe: "https://www.youtube.com/embed/8zDgxqIdnYQ?si=4HaWzH5FHgdPlvM4",
  },
  {
    name: "Tiwa Garden City Allocation (Testimonial)",
    type: "land",
    iframe: "https://www.youtube.com/embed/UPDTo2YaylA?si=zrw7K-rwwptXB6WZ",
  },
  {
    name: "TIWA ALLOCATION TESTIMONIAL",
    type: "land",
    iframe: "https://www.youtube.com/embed/LYxCJ3Mx_cE?si=X_57SW73iau7bqm-",
  },
  //   {
  //     name: "TIWA ALLOCATION TESTIMONIAL",
  //     type: "land",
  //     iframe: "https://www.youtube.com/embed/LYxCJ3Mx_cE?si=-mKnYdum6WkHQejG",
  //   },
  {
    name: "AY Comedian Invests in the newly launched Real Estate Property Tiwa Garden City",
    type: "land",
    iframe: "https://www.youtube.com/embed/1WqqtebAQ9M?si=HS2h-7HsONmCjbru",
  },
  {
    name: "Land allocation of the most affordable property on the mainland - Western Hilltop Estate",
    type: "land",
    iframe: "https://www.youtube.com/embed/Hj9OBzm6P7M?si=5brqQooZq93GMHcW",
  },
];
